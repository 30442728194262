import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";

import { actions } from "../../../redux/store/store";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import Select from "react-select";
import { useGetCompanyQuery } from "../../../api/company";
import { metroOptionsStyles } from "./metros/metroSelect";
import { states } from "../../../constants/variables/states";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

function CompanyModal(props) {
    const title = props?.id?.title
    const { id } = props;
    const companyId = props && props?.id?.id && props?.id?.id;
    const data = props?.id

    const companyCode = data?.company?.companyCode === undefined ? "" : data?.company?.companyCode
    const companyName = data?.company?.companyName === undefined ? "" : data?.company?.companyName

    const { data: getproductData, isFetching } = useGetCompanyQuery(null, {
        refetchOnMountOrArgChange: true,
    })

    const [selectedOption, setSelectedOption] = useState(data?.company === null || data?.company === undefined ? null : {
        value: `${companyCode} ${companyCode && companyName ? "-" : ""} ${companyName}`,
        label: `${companyCode} ${companyCode && companyName ? "-" : ""} ${companyName}`,
        id: data?.company?._id || null
    });

    const [selectedStateOption, setSelectedStateOption] = useState(props?.id?.company === null ? null : {
        value: ` ${props?.id?.company}`,
        label: `${props?.id?.company}`,
        id: props?.id?.id || null
    })

    const [options, setOptions] = useState([]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
        useUpdateDriverMutation();

    const onNotesSubmit = useCallback(
        async () => {
            let params = {}
            if (title === "Company") {
                params = {
                    company: selectedOption?.id || null,
                    _id: companyId,
                }
            } else if (title === "State") {
                params = {
                    state: selectedStateOption?.label || null,
                    _id: props?.id?.id,
                }
            }
            let result = await updateDriverMetro(params);
            if (!result?.error) {
                sendSuccessMessage(`${title} updated successfully`);
                actions.refetch.setIsDataRefetch(true);
            } else {

                checkForError(result);
            }
            actions.modal.closeCompany();
        },
        [
            id,
            companyId,
            updateDriverMetro,
            selectedOption,
            selectedStateOption
        ]
    );

    useEffect(() => {
        if (title === "Company") {
            const data = !isFetching && getproductData?.length > 0 &&
                getproductData?.map(function (item) {
                    return {
                        value: `${item?.companyCode ? item?.companyCode : ""}${item?.companyName ? item?.companyName : ""}`,
                        label: `${item?.companyCode ? item?.companyCode : ""} ${item?.companyName && item?.companyCode ? "-" : ""} ${item?.companyName ? item?.companyName : ""}`,
                        id: item._id
                    };
                })
            data && setOptions(data);
        }
        if (title === "State") {
            const data = !isFetching && states?.length > 0 &&
                states.map(function (item) {
                    return {
                        value: `${item?.value ? item?.value : ""}`,
                        label: `${item?.title ? item?.title : ""}`,
                        id: item._id
                    }
                })
            data && setOptions(data)
        }


    }, [getproductData, isFetching, states]);

    const onCancel = useCallback(() => {
        actions.modal.closeCompany();
    }, []);


    return (
        <>
            <Modal
                open
                onClose={onCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                    <div className="modal__header">
                        <h2 className="modal__title">{`Update ${title}`}</h2>
                    </div>
                    <div className="modal__body">
                        <form style={{ height: isMenuOpen ? "20vh" : "auto" }}>
                            <Select
                                onChange={(item) => {
                                    title === "Company" ? setSelectedOption(item) : setSelectedStateOption(item)
                                }}
                                options={options}
                                value={title === "Company" ? selectedOption : selectedStateOption}
                                styles={metroOptionsStyles}
                                isClearable={true}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                closeMenuOnSelect={true}
                                onMenuClose={() => setIsMenuOpen(false)}
                                onMenuOpen={() => setIsMenuOpen(true)}
                                menuIsOpen={isMenuOpen}
                                placeholder={`Select ${title}`}
                            />
                        </form>
                    </div>
                    <div className="modal__footer">
                        <button className="btn" onClick={() => actions.modal.closeCompany()}>
                            Cancel
                        </button>
                        <div style={{ position: 'relative' }}>
                            <button
                                className="btn btn_accent"
                                onClick={onNotesSubmit}
                                disabled={isUpdatingDriver}
                            >
                                OK
                            </button>
                            {isUpdatingDriver && (
                                <CircularProgress size={18}
                                    sx={{
                                        color: '#fff',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '83%',
                                        marginTop: '-8px',
                                        marginLeft: '-10px',
                                    }} />
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CompanyModal
