import React from 'react'
import HeaderTextLayout from '../../../components/shared/HeaderTextLayout'
import { IoIosAddCircle } from 'react-icons/io'
import { CircularProgress } from '@mui/material'
import Table from '../../../components/shared/Table'
import { useLocation } from 'react-router-dom'
import { actions } from '../../../redux/store/store'
import { useEffect } from 'react'
import { useDeletePaymentCalendarMutation, useGetAllPaymentCalendarQuery } from '../../../api/paymentCalendar'
import { useState } from 'react'
import details_icon from "../../../images/details.svg"
import delete_icon from "../../../images/icons8-trash-24.svg"
import moment from 'moment'
import { PAY_CAL_FOUND } from '../../../constants/variables'
import { format } from 'date-fns'
import ConfirmDialog from '../../../components/shared/customModal/confirmDialog'
import { useCallback } from 'react'
import { checkForError } from '../../../constants/extras/errorHandlers'
import { handleBackgroundCheck } from '../../../constants/extras/statusesBadge'
import payment_detail from "../../../images/payment_detail.svg"
import { useSelector } from 'react-redux'
import PaymentFailedDetailModal from '../../../components/shared/customModal/paymentCalendar/paymentFailedDetailModal'

const PaymentCalendar = () => {
  const paymentFailed = useSelector((state) => state.modal.failedPayment);

  const location = useLocation();
  const { pathname } = location;

  const { data: payment, isFetching: isPaymentLoading } = useGetAllPaymentCalendarQuery("", {
    refetchOnMountOrArgChange: true
  });
  const [deletePaymentCalendar] = useDeletePaymentCalendarMutation()

  const [paymentCalendar, setPaymentCalendar] = useState([])
  const [removePaymentCalendar, setRemovePaymentCalendar] = useState([false, ""]);

  useEffect(() => {
    actions.refetch.setIsDataRefetch(true);
  }, [pathname])

  useEffect(() => {
    setPaymentCalendar(payment?.result)
  }, [payment])


  const handleClickDelete = useCallback(
    async (val) => {
      const result = await deletePaymentCalendar(val);
      if (!result?.data?.error && result.data) {
        actions.notifications.add({
          message: `Data Removed Successfully`,
          status: "success",
        });
        setRemovePaymentCalendar([false, ""]);
      } else {
        checkForError(result);
        setRemovePaymentCalendar([false, ""]);
      }
    },
    []
  );

  const columns = [
    {
      name: "schedulePaymentDate", label: "Scheduled Payment Date", sortable: true, className: "p-2 w-12",
      renderer: (item) => item && item?.schedulePaymentDate && (<span>
        {moment(item?.schedulePaymentDate?.split("T")[0]).format("MM-DD-YYYY")} ({moment(item?.schedulePaymentDate?.split("T")[0]).format('dddd')})
      </span>)
    },

    {
      name: "executionPaymentDate", label: "Executed Payment Date", sortable: true, className: "w-12",
      renderer: (item) => item && item?.executionPaymentDate && (<span>
        {moment(item?.executionPaymentDate).format("MM-DD-YYYY hh:mm A")}
      </span>)
    },
    {
      name: "successfullPayments", label: "Successfull Payments", sortable: true, className: "w-12",
      renderer: (item) => item?.executionPaymentDate ? (
        <span
          className="textStyleForStatus"
          style={handleBackgroundCheck(true)}
        >
          {item?.successfullPayments}
        </span>
      ) : '-',
    },
    {
      name: "unsuccessfullPayments", label: "Failed Payments", sortable: true, className: "w-12",
      renderer: (item) => item?.executionPaymentDate ? (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span
            className="textStyleForStatus"
            style={handleBackgroundCheck(false)}
          >
            {item?.unsuccessfullPayments}
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => actions.modal.openFailedPayment(item)}
          >
            <img src={payment_detail} alt="" />
          </span>
        </div>
      ) : '-',
    },
    { name: "notes", label: "Notes", sortable: true },
    {
      name: "details",
      className: "table__td_have-btn w-5 text-center px-3",
      renderer: (item) => (
        <>
          <div style={{ display: 'flex', gap: '0.5em' }}>
            <div
              onClick={() => {
                actions.modal.openCalendarPayment(item);
              }}
              style={{ fontSize: "85%", padding: "0 0.3rem", cursor: "pointer" }}
            >
              <img src={details_icon} alt="" width="20px" />
            </div>
            {
              !item?.executionPaymentDate &&
              <div
                onClick={() => {
                  setRemovePaymentCalendar([true, item._id])
                }}
                style={{ fontSize: "85%", padding: "0 0.3rem", cursor: "pointer" }}
              >
                <img src={delete_icon} alt="" width="20px" />
              </div>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {removePaymentCalendar[0] && (
        <ConfirmDialog
          bodyText="Are you sure you want to remove this data?"
          isOpen
          onClose={() => {
            setRemovePaymentCalendar([false, ""]);
          }}
          confirmAction={() => {
            handleClickDelete(removePaymentCalendar[1]);
          }}
        />
      )}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText="Payment Calendar"
                className="col-md-2 main__driver"
                data={
                  paymentCalendar && paymentCalendar.length
                    ? `${paymentCalendar.length < 10 ? `0${paymentCalendar.length}` : paymentCalendar.length} ${PAY_CAL_FOUND}`
                    : ""
                }
              >
                <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center filter">
                  <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center">
                    <button
                      style={{
                        background: "white",
                        borderColor: "#e6e6e6",
                        width: "120px",
                      }}
                      disabled={isPaymentLoading}
                      className="btn btn__accent mr-0 ml-3"
                      onClick={() => actions.modal.openCalendarPayment()}
                    >
                      <IoIosAddCircle style={{ fontSize: "22px" }} />
                      Add New
                    </button>
                  </div>
                </div>
              </HeaderTextLayout>

              {isPaymentLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : (
                <React.Fragment>
                  {paymentCalendar && paymentCalendar.length ? (
                    <div className="table-wrapper normal_table">
                      <Table
                        columns={columns}
                        items={paymentCalendar}
                        className="table_metros"
                      />
                    </div>
                  ) : (
                    <div className="no_results">No Results</div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      {paymentFailed.open && <PaymentFailedDetailModal id={paymentFailed.id} />}

    </>
  )
}

export default PaymentCalendar