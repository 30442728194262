import { Calendar } from "react-date-range";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Box } from "@mui/material";
import moment from "moment";
import "./style.scss";

export const DateCalendar = (props) => {
    const { handleCalendarDate, showDatePicker, onChange, submitDate, cancelDate,
        resetDate, sx, placeholder, date, showDate, minDate, maxDate } = props
  
    const styles = {
        position: 'absolute',
        top: 37,
        right: 0,
        left: 0,
        zIndex: 1,
        p: 1,
        width: "103%"
    };
    return (
        <>
            <Box sx={sx}>
                <div onClick={handleCalendarDate} className="form__input" style={{ borderRadius: "2px", border: "1px solid #e3eaf3", color: "#838383", fontSize: "0.9rem", display: "flex", justifyContent: "space-between" }}>
                    <span style={{fontSize: '1rem'}}>
                        {showDate ? `${moment(showDate).format(
                            "MM-DD-yyyy"
                        )}`
                            :  placeholder ? placeholder : "Select Date"}
                    </span>
                    <span className="pl-2">
                        {showDatePicker ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </span>
                </div>
                {showDatePicker && (
                    <Box sx={styles} className="dt__rng__pickr__cont">
                        <div className="dateRangePicker" style={{ right: "!important" }}>
                           
                            <Calendar
                              date={date}
                              onChange={onChange}
                              minDate={minDate}
                              maxDate={maxDate}
                            />
  
                            <div className="calendar-button-container">
                                <button
                                    onClick={resetDate}
                                    className="okButton clearButton"
                                >
                                    <span>
                                        <BackspaceIcon /> &nbsp;
                                        <strong>RESET</strong>
                                    </span>
                                </button>
                                <button
                                    onClick={cancelDate}
                                    className="cancelButton"
                                >
                                    <span>
                                        <CancelIcon sx={{ width: "1.5rem", height: "1.5rem" }} />{" "}
                                        <strong>cancel</strong>
                                    </span>
                                </button>
                                <button
                                    onClick={submitDate}
                                    className="okButton"
                                >
                                    <span>
                                        <ThumbUpAltIcon /> &nbsp;
                                        <strong>Done</strong>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Box>
                )}
            </Box>
        </>
    )
  }