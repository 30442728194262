import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { baseQueryWithAuthInterceptor } from "./util";
var qs = require('qs');

export const driversApi = createApi({
  reducerPath: "driversApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/drivers" }),
  tagTypes: ["Driver"],
  endpoints: (builder) => ({
    getDrivers: builder.query({
      query: (body) => {
        let opts = {};
        if (body && body.forOrderId) {
          opts.params = { forOrderId: body.forOrderId };
        }
        if (body && body.metro) {
          opts.params = { metro: body.metro };
        } else if (body && body.status) {
          opts.params = { status: body.status };
        }
        else if (body && body.company) {
          opts.params = { ...opts.params, company: body.company }
        }
        if (body && body.status) {
          opts.params = { ...opts.params, status: body.status };
        }
        if (body && body.metro) {
          opts.params = { ...opts.params, metro: body.metro }
        }
        if (body && body.company) {
          opts.params = { ...opts.params, company: body.company }
        }
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["Driver"],
    }),
    getInterestedDrivers: builder.query({
      query: (body) => {
        const opts = {};
        if (body && body.forOrderId) {
          opts.params = { forOrderId: body.forOrderId };
        }
        return {
          url: "get/interested",
          ...opts,
        };
      },
      providesTags: ["Driver"],
    }),
    getDriver: builder.query({
      query: (id) => id,
      providesTags: ["Driver"],
    }),

    updateDriver: builder.mutation({
      query: (body) => ({
        url: body._id,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    createOrUpdateCustomer: builder.mutation({
      query: (body) => ({
        url: `${body._id}/createOrUpdateCustomer`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !result.error && [{ type: "Driver", _id: result._id }],
    }),
    createOrUpdateFundingSource: builder.mutation({
      query: (body) => ({
        url: `${body._id}/createOrUpdateFundingSource`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !result.error && [{ type: "Driver", _id: result._id }],
    }),
    removeDriver: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => !error && ["Driver"],
    }),
    approveDriver: builder.mutation({
      query: (body) => ({
        url: `${body._id}/approve`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    rejectDriver: builder.mutation({
      query: (body) => ({
        url: `${body._id}/reject`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    blockDriver: builder.mutation({
      query: (body) => ({
        url: `${body._id}/block`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    unblockDriver: builder.mutation({
      query: (body) => ({
        url: `${body._id}/unblock`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    getAdhocPayment: builder.query({
      query: (body) => ({
        url: `get/adhocpayment/${body.day}`,
      }),
      providesTags: ["Driver"],
    }),
    getTransactionStatus: builder.mutation({
      query: (id) => ({
        url: `transactionStatus/${id}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    paymentDriver: builder.mutation({
      query: (body) => ({
        url: `${body._id}/pay`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    createAccount: builder.mutation({
      query: (body) => ({
        url: `/createAccount`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !result.error && [{ type: "Driver", _id: result._id }],
    }),
    getDirectPayee: builder.query({
      query: (body) => ({
        url: "get/directPayee",
      }),
      providesTags: ["Driver"],
    }),
    removeDirectpayee: builder.mutation({
      query: (id) => ({
        url: `${id}/directPayee`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => !error && ["Driver"],
    }),
    getSingleDirectPayment: builder.query({
      query: (id) => ({
        url: `${id}/directPayee`,
        method: "GET",
      }),
      providesTags: ["Driver"],
    }),
    getPayees: builder.query({
      query: (id) => ({
        url: `list/AdhocPayment`,
        method: "GET",
      }),
      providesTags: ["Driver"],
    }),
    approvePayment: builder.mutation({
      query: (body) => ({
        url: `${body._id}/approvePayment`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    getApprovePaymentList: builder.query({
      query: (body) => ({
        url: `/get/approvedPaymentList?adhocPayments=${body?.adhocPayments}&instantPayments=${body?.instantPayments} &exceptionPayments=${body?.exceptionPayments} &weeklyPayments=${body?.weeklyPayments}&fromDate=${body?.fromDate || ""}&toDate=${body?.toDate || ""}${body?.isPaymentFail ? '&isPaymentFail=true' : ''}`,
        method: "GET",
      }),
      providesTags: ["Driver"],
    }),
    adhocPayment: builder.mutation({
      query: (body) => ({
        url: `${body._id}/payment`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    rejectDrivePayment: builder.mutation({
      query: (body) => ({
        url: `${body._id}/rejectPayment`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
    getDriversEarningReport: builder.query({
      query: (body) => {
        const opts = {};
        if (body) {
          opts.params = { fromDate: body.fromDate, toDate: body.toDate, paymentMode: body?.paymentMode !== null ? body?.paymentMode?.value : "" };
        }
        return {
          url: `/get/earnings`,
          ...opts,
          method: "GET",
        };
      },
      providesTags: ["Driver"],
    }),
    deleteDriver: builder.mutation({
      query: (body) => ({
        url: `${body?._id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Driver"],
    }),
    DriverInactive: builder.mutation({
      query: (body) => ({
        url: `${body?._id}/inactive`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Driver"],
    }),
    backgroundCheck: builder.mutation({
      query: (body) => ({
        url: `${body?._id}/checkrBackground`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Driver"],
    }),
    getOneplusShift: builder.query({
      query: (body) => {
        const opts = {};
        if (body && body.forOrderId) {
          opts.params = { forOrderId: body.forOrderId };
        }
        if (body && body.metro) {
          opts.params = { metro: body.metro };
        } else if (body && body.status) {
          opts.params = { status: body.status };
        }
        else if (body && body.company) {
          opts.params = { company: body.company }
        }
        if (body && body.status) {
          opts.params = { ...opts.params, status: body.status };
        }
        if (body && body.company) {
          opts.params = { ...opts.params, company: body.company };
        }
        if (body && body.metro) {
          opts.params = { ...opts.params, metro: body.metro }
        }
        if (body && body.isMetroAll) {
          opts.params = { ...opts.params, isMetroAll: body.isMetroAll }
        }
        if (body && body.isCompanyAll) {
          opts.params = { ...opts.params, isCompanyAll: body.isCompanyAll }
        }

        if (body && ["assigned", "delivery_started"].includes(body.status) && body.fromDate && body.toDate) {
          opts.params = { ...opts.params, fromDate: body.fromDate, toDate: body.toDate }
        }

        if (body && body.status && body.metro && body.fromDate && body.toDate) {
          opts.params = {
            ...opts.params,
            metro: body.metro,
            status: body.status,
            fromDate: body.fromDate,
            toDate: body.toDate,
          };
        }

        return {
          url: "/notifications/get",
          method: "GET",
          ...opts,
        };
      },
      providesTags: ["Driver"],
    }),
    Review: builder.mutation({
      query: (body) => ({
        url: `/verify/BackgroundChecks`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Driver"],
    }),

  }),
});

export const {
  useGetDriversQuery,
  useLazyGetInterestedDriversQuery,
  useLazyGetDriversQuery,
  useUpdateDriverMutation,
  useGetDriverQuery,
  useRemoveDriverMutation,
  useCreateOrUpdateCustomerMutation,
  useCreateOrUpdateFundingSourceMutation,
  useApproveDriverMutation,
  useRejectDriverMutation,
  useBlockDriverMutation,
  useUnblockDriverMutation,
  useGetAdhocPaymentQuery,
  useGetTransactionStatusMutation,
  usePaymentDriverMutation,
  useCreateAccountMutation,
  useGetDirectPayeeQuery,
  useRemoveDirectpayeeMutation,
  useGetSingleDirectPaymentQuery,
  useGetPayeesQuery,
  useApprovePaymentMutation,
  useGetApprovePaymentListQuery,
  useAdhocPaymentMutation,
  useRejectDrivePaymentMutation,
  useGetDriversEarningReportQuery,
  useDeleteDriverMutation,
  useDriverInactiveMutation,
  useBackgroundCheckMutation,
  useGetOneplusShiftQuery,
  useReviewMutation
} = driversApi;

export const getDriversList = async (
  page,
  limit,
  licenseStatusFilter,
  searchQuery,
  metro,
  shifFromDate,
  shifToDate,
  firstOrderDriver,
  isShiftCount,
  isBackgroundCheck,
  orderCountFrom,
  orderCountTo,
  scoreFrom,
  scoreTo,
  state,
  onboardingFrom,
  onboardingTo,
  intrestDriversFromDate,
  intrestDriversToDate,
  company,
  isNotSelected,
  daysData,
  timeData,
  timeAmount,
  coaching,
  btReview,
  expiredFromDate,
  expiredToDate,
  expired
) => {
  try {
    const response = await axios.get(
      `/api/drivers?page=${page ? page : ""}&limit=${limit ? limit : ""
      }&search=${searchQuery}&shifFromDate=${shifFromDate}&shifToDate=${shifToDate}
      &firstOrderDriver=${firstOrderDriver}&isShiftCount=${isShiftCount}&orderCountFrom=${orderCountFrom}&orderCountTo=${orderCountTo}
      &scoreFrom=${scoreFrom}&scoreTo=${scoreTo}&isBackgroundCheck=${isBackgroundCheck}&onBoardFrom=${onboardingFrom}&onBoardTo=${onboardingTo}
      &InterestedFrom=${intrestDriversFromDate}&InterestedTo=${intrestDriversToDate}&isNotSelected=${isNotSelected}&isTotal=${true}&isCreated=${true}
      &timeAmount=${timeAmount?.id || ""}&isCoaching=${coaching}&isbtReviewUnverified=${btReview}&expirationFromDate=${expiredFromDate}&expirationToDate=${expiredToDate}&isExpired=${expired}`,
      {
        'params': { 'metro': metro, "state": state, "status": licenseStatusFilter, "company": company, "availableDays": daysData, "availableTime": timeData },
        'paramsSerializer': function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
      }
    );
    if (response.status === 200) return response?.data;
    else return "Something went wrong";
  } catch (e) {
    return e.response.data;
  }
};

export const getApprovePaymentList = async (
  limit,
  page,
  searchQuery,
  PaymentMethod,
  adhocPayments,
  instantPayments,
  exceptionPayments,
  weeklyPayments,
  fromDate,
  toDate,
  isPaymentFail
) => {
  try {
    const response = await axios.get(
      `/api/drivers/get/approvedPaymentList?limit=${limit}&page=${page}&search=${searchQuery}&adhocPayments=${adhocPayments}&instantPayments=${instantPayments}&exceptionPayments=${exceptionPayments} &weeklyPayments=${weeklyPayments}&fromDate=${fromDate || ''}&toDate=${toDate || ''}${isPaymentFail ? '&isPaymentFail=true' : ''}&isTotal=${true}&paymentMode=${PaymentMethod?.value || ""}`,
    );
    if (response.status === 200) return response?.data;
    else return 'Something went wrong';
  } catch (e) {
    return e.response.data;
  }
};
