import { RiBuilding4Fill, RiBuilding2Fill } from "react-icons/ri";
import { FaUsers, FaRegListAlt, FaChalkboardTeacher, FaTruck, FaRegCommentDots, FaHubspot, FaTrain, FaRegCalendar } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { GoReport } from "react-icons/go";
import { RiRecycleFill } from "react-icons/ri";
import { CgTemplate } from "react-icons/cg";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { RiSecurePaymentFill } from "react-icons/ri";
import { AiFillDollarCircle } from "react-icons/ai";
import { GiModernCity } from "react-icons/gi";
import { AiOutlineMail } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdCable, MdPayment } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { MdConnectedTv, MdFeedback } from "react-icons/md";
import { BsFillChatRightTextFill } from "react-icons/bs"
import { TbReportAnalytics, TbTemplate } from "react-icons/tb"
import { SiAzurepipelines } from "react-icons/si"
import TruckLogo from './images/better-truck-logo.svg'

import { ADHOC } from "../src/constants/variables/index";

const RootAndAdmin = ["root", "admin"];
const RootOnly = ["root"];
const BookkeeperOnly = ["bookkeeper"];
const RootAdnBookkeeper = ["root", "bookkeeper"];
const RootAdminAndBookkeeper = [...RootAndAdmin, ...BookkeeperOnly]
const RootAdminAndMetroManager = [...RootAndAdmin, "metro_manager"];

// export const routesListDummy = [
//   { path: "hubs", icon: <RiBuilding4Fill />, role: RootOnly },
//   {
//     path: "metros",
//     icon: <GiModernCity />,
//     role: RootOnly,
//   },
//   { path: "company", icon: <RiBuilding2Fill />, role: RootOnly },
//   {
//     path: "adhocpayment",
//     icon: <RiRecycleFill />,
//     name: ADHOC,
//     role: RootAndAdmin,
//   },
//   // {
//   //   path: "directpayment",
//   //   icon: <MdPayment />,
//   //   name: "Direct Payee",
//   //   role: ["admin", "root"],
//   // },
//   { path: "drivers", icon: <FaUsers />, role: RootAdminAndMetroManager },
//   { path: "drivercoaching", icon: <FaChalkboardTeacher />, role: RootAdminAndMetroManager, name: "Driver Coaching", },
//   {
//     path: "operator",
//     icon: <MdConnectedTv />,
//     role: RootAndAdmin,
//   },
//   // {
//   //   path: "driversreport",
//   //   icon: <GoReport />,
//   //   name: "Driver Report",
//   //   role: RootAndAdmin,
//   // },
//   {
//     path: "users",
//     icon: <ImUsers />,
//     currentUser: true,
//     role: RootOnly,
//   },
//   {
//     path: "shifts",
//     icon: <FaRegListAlt />,
//     role: RootAdminAndMetroManager,
//   },
//   // {
//   //   path: "shiftpage",
//   //   icon: <CgTemplate />,
//   //   name: "shift Page",
//   //   role: RootAdminAndMetroManager,
//   // },
//   {
//     path: "shiftstemplate",
//     icon: <CgTemplate />,
//     name: "Shifts Template",
//     role: RootAndAdmin,
//   },
//   {
//     path: "deposit",
//     icon: <CgTemplate />,
//     currentUser: true,
//     role: BookkeeperOnly,
//   },
//   // {
//   //   path: "links-to-docs",
//   //   icon: <HiOutlineDocumentDuplicate />,
//   //   name: "Docs",
//   //   role: RootAndAdmin,
//   // },
//   {
//     path: "payments",
//     icon: <RiSecurePaymentFill />,
//     role: RootAdnBookkeeper,
//   },
//   {
//     path: "paymentsCalendar",
//     icon: <FcCalendar />,
//     role: RootOnly,
//     name: 'Payment Calendar'
//   },
//   { path: "feedback", icon: <MdFeedback />, role: RootAdminAndMetroManager },
//   {
//     path: "emails",
//     icon: <AiOutlineMail />,
//     role: RootAndAdmin,
//   },
//   {
//     path: "notifications",
//     icon: <IoIosNotificationsOutline />,
//     role: RootAndAdmin,
//   },
//   {
//     path: "scheduling",
//     icon: <FcCalendar />,
//     role: RootAdminAndMetroManager,
//   },
//   {
//     path: "schedulers",
//     name: "Pipeline",
//     icon: <MdCable />,
//     role: RootAndAdmin,
//   },
//   {
//     path: "piplinestage",
//     icon: <MdCable />,
//     name: "Pipeline Stage",
//     role: RootAndAdmin,
//   },
//   {
//     path: "reports",
//     name: "Reports",
//     icon: <GoReport />,
//     role: RootAdnBookkeeper,
//     subRoutes: [
//       {
//         path: "/reports/drivers-earning",
//         name: "Drivers Earning",
//         icon: <AiFillDollarCircle />,
//       },
//       {
//         path: "/reports/transactions",
//         name: "Transactions",
//         icon: <CompareArrowsIcon />,
//       },
//     ],
//   },
// ];

export const routesList = [
  {
    path: "Bettertrucks",
    name: "Bettertrucks",
    icon: TruckLogo,
    role: RootAdminAndMetroManager,
    subPathArray: ['/hubs', '/metros', '/company', '/users', '/shiftstemplate'],
    subRoutes: [
      {
        path: "hubs",
        name: "Hubs",
        icon: <RiBuilding4Fill />,
        role: RootOnly
      },
      {
        path: "metros",
        name: "Metros",
        icon: <GiModernCity />,
        role: RootOnly
      },
      { path: "company", name: 'Company', icon: <RiBuilding2Fill />, role: RootOnly },
      {
        path: "users",
        name: 'Users',
        icon: <ImUsers />,
        currentUser: true,
        role: RootOnly,
      },
      {
        path: "shiftstemplate",
        icon: <TbTemplate />,
        name: "Shifts Template",
        role: RootAndAdmin,
      },
    ],
  },
  
  { path: "drivers", icon: <FaUsers />, role: RootAdminAndMetroManager },
  {
    name: 'Operators',
    path: "operator",
    icon: <MdConnectedTv />,
    role: RootAndAdmin,
  },
  {
    path: "shifts",
    icon: <FaRegListAlt />,
    role: RootAdminAndMetroManager,
  },
  {
    path: "deposit",
    icon: <CgTemplate />,
    currentUser: true,
    role: BookkeeperOnly,
  },
  {
    path: "communication",
    name: "Driver communication",
    icon: <BsFillChatRightTextFill />,
    role: RootAdminAndMetroManager,
    subPathArray: ['/feedback', '/emails', '/notifications', '/drivercoaching'],
    subRoutes: [
      { path: "drivercoaching", icon: <FaChalkboardTeacher />, role: RootAdminAndMetroManager, name: "Driver Coaching" },
      { path: "feedback", icon: <MdFeedback />, role: RootAdminAndMetroManager },
      {
        path: "emails",
        icon: <AiOutlineMail />,
        role: RootAndAdmin,
      },
      {
        path: "notifications",
        icon: <IoIosNotificationsOutline />,
        role: RootAndAdmin,
      }
    ],
  },
  {
    path: "pay",
    name: "Payments",
    icon: <MdPayment />,
    role: RootAdminAndBookkeeper,
    subPathArray: ['/payments', '/paymentsCalendar', '/adhocpayment'],
    subRoutes: [
      {
        path: "payments",
        icon: <RiSecurePaymentFill />,
        role: RootAdnBookkeeper,
      },
      {
        path: "paymentsCalendar",
        icon: <FaRegCalendar />,
        role: RootOnly,
        name: 'Payment Calendar'
      },
      {
        path: "adhocpayment",
        icon: <RiRecycleFill />,
        name: ADHOC,
        role: RootAndAdmin,
      },
    ],
  },

  {
    path: "scheduling",
    icon: <FcCalendar />,
    role: RootAdminAndMetroManager,
  },

  {
    path: "reports",
    name: "Reports",
    icon: <TbReportAnalytics />,
    role: RootAdminAndBookkeeper,
    subPathArray: ['/schedulers/pipeline_drivers', '/piplinestage', '/reports/drivers-earning', '/reports/transactions'],
    subRoutes: [
      {
        path: "schedulers",
        name: "Pipeline",
        icon: <SiAzurepipelines />,
        role: RootAndAdmin,
      },
      {
        path: "piplinestage",
        icon: <MdCable />,
        name: "Driver onboarding pipeline",
        role: RootAndAdmin,
      },
      {
        path: "/reports/drivers-earning",
        name: "Drivers Earning",
        icon: <AiFillDollarCircle />,
        role: RootAdnBookkeeper,
      },
      {
        path: "/reports/transactions",
        name: "Transactions",
        icon: <CompareArrowsIcon />,
        role: RootAdnBookkeeper,
      },
    ],
  },
]