import React, { useState } from 'react'
import { Drawer, Box, Typography, FormControlLabel, styled, Switch, Modal, InputLabel, CircularProgress, Paper, ListItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { BsFillFilterCircleFill } from 'react-icons/bs';
import "./style.driver.scss"
import { states } from '../../../../constants/variables/states';
import MultiSelect from '../../../../constants/multiSelectDropdown';
import { MdPlace } from "react-icons/md";
import { useGetMetrosQuery } from '../../../../api/metro';
import { driverReportStatus } from '../../../../constants/menuLists';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { DateRangePicker } from 'react-date-range';
import { Label } from '../../Form';
import { addDays } from 'date-fns';
import ReactSelect from 'react-select';
import { useDriverSavedFilterMutation, useGetSavedFilterDataQuery, useUpdateSavedFilterMutation } from '../../../../api/savedFilter';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sendSuccessMessage } from '../../../../constants/extras/sendSuccessMsg';
import { sendErrorMessage } from "../../../../constants/extras/errorHandlers";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useGetCompanyQuery } from '../../../../api/company';
import { filterReactSelectStyle } from '../../../../constants/multiSelectDropdown/multiSelectStyle';
import { scheduleTime, sched_init, weekdays } from '../../../../constants/variables/arrays';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 6,
    '& .MuiSwitch-switchBase': {
        transitionDuration: '500ms',
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 20,
        height: 20,
        margin: 0,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#ccd7dd' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 1000,
        }),
    },
}));

const MuiAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    },
}));

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const MuiAccordionSummary = styled((props) => (
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#ccd7dd',

    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

function CustomDrawer(props) {
    const { openDrawer, handleCloseDrawer, handleApplyFilter, handleReset, handleCustomRange, handleTimeRangeSelection, onBoardingRange, handleOnboardedTimeRange,
        intrestDriverRange, handleIntrestDriverTimeRange, onSaveDataChange, expiryRange, handleExpiryTimeRange, obj } = props
    const {
        selectState,
        setSelectState,
        metro,
        setMetro,
        statusFilter,
        setStatusFilter,
        fromShiftRange,
        setFromShiftRange,
        toShiftRange,
        setToShiftRange,
        fromScoreRange,
        setFromScoreRange,
        toScoreRange,
        setToScoreRange,
        isFirstDriver,
        setIsFirstDriver,
        isShiftStatus,
        setIsShiftStatus,
        isBackgroundCheck,
        setIsBackgroundCheck,
        fromDate,
        toDate,
        showDateRangePicker,
        setState,
        state,
        setFromDate,
        setToDate,
        setShowDateRangePicker,
        showOnboardPicker,
        setShowOnboardPicker,
        setOnboardingState,
        onboardingstate,
        onboardingFrom,
        onboardingTo,
        setOnboardingFrom,
        setOnboardingTo,
        setShowIntrestDrivers,
        showIntrestDrivers,
        intrestDriversFromDate,
        setIntrestDriversFromDate,
        intrestDriversToDate,
        setIntrestDriversToDate,
        intrestDriverState,
        setIntrestDriverState,
        saveData,
        hideColumn,
        columndata,
        setColumnData,
        getID,
        data,
        setCompanyData,
        companyData,
        days,
        setDays,
        time,
        setTime,
        timeAmount,
        setTimeAmount,
        isCoaching,
        setIsCoaching,
        isBtReview,
        setIsBtReview,
        showExpired,
        setShowExpired,
        expiredFromDate,
        setExpiredFromDate,
        expiredToDate,
        setExpiredToDate,
        expiredState,
        setExpiredState,
        isExpired,
        setIsExperied,
    } = obj

    const styles = (width) => {
        return {
            placeholder: (base) => ({
                ...base,
                fontSize: "0.9rem",
                fontWeight: "normal"
            }),
            control: (styles) => ({
                ...styles,
                borderRadius: "2px",
                boxShadow: "none",
                width: width || "12rem",
                height: "2.8rem",
                "&:hover": {
                    borderColor: "#e3eaf3",
                },
                borderColor: "#e3eaf3",
                fontSize: "1rem",
            }),
        }

    }

    const currentUser = useSelector((state) => state.auth.currentUser)

    const { data: metros } = useGetMetrosQuery(null, {
        refetchOnMountOrArgChange: true,
    });

    const { data: getsavedData, isFetching } = useGetSavedFilterDataQuery({ id: currentUser?._id, screen: "driver" }, {
        refetchOnMountOrArgChange: true,
        skip: !openDrawer
    })

    const { data: companies } = useGetCompanyQuery(null, {
        refetchOnMountOrArgChange: true,
    })

    const [savedFilter, savedData] = useDriverSavedFilterMutation()
    const [updateSavedFilter, updateData] = useUpdateSavedFilterMutation()

    const [title, setTitle] = useState("");
    const [openModal, setOpenModal] = useState(false)
    const [isTitle, setIsTitle] = useState(false)
    const [getCompany, setGetCompany] = useState([])

    useEffect(() => {
        let data = [];
        data = companies && companies?.map(item => {
            return {
                value: `${item?.companyCode ? item?.companyCode : ""} ${item?.companyName && item?.companyCode ? "-" : ""} ${item?.companyName ? item?.companyName : ""}`,
                label: `${item?.companyCode ? item?.companyCode : ""} ${item?.companyName && item?.companyCode ? "-" : ""} ${item?.companyName ? item?.companyName : ""}`,
                companyName: item?.companyName,
                id: item?._id
            }
        })
        data?.unshift({ label: "Not Selected", value: "Not Selected", id: null })
        setGetCompany(data)
    }, [companies])


    useEffect(() => {
        if (saveData === "") {
            setColumnData(data)
        }

    }, [saveData])

    const clearTimeRange = () => {
        setFromDate("");
        setToDate("");
        setShowDateRangePicker(false);
        setState([
            {
                startDate: new Date(),
                endDate: addDays(new Date(), 0),
                key: "selection",
            },
        ]);
    }

    const clearOnboardingTimeRange = () => {
        setOnboardingFrom("");
        setOnboardingTo("");
        setShowOnboardPicker(false);
        setOnboardingState([
            {
                startDate: new Date(),
                endDate: addDays(new Date(), 0),
                key: "selection",
            },
        ]);
    }

    const clearintrestDriverTimeRange = () => {
        setIntrestDriversFromDate("");
        setIntrestDriversToDate("");
        setShowIntrestDrivers(false);
        setOnboardingState([
            {
                startDate: new Date(),
                endDate: addDays(new Date(), 0),
                key: "selection",
            },
        ]);
    }

    const clearExpiryDriverTimeRange = () => {
        setExpiredFromDate("");
        setExpiredToDate("");
        setShowExpired(false);
        setExpiredState([
            {
                startDate: new Date(),
                endDate: addDays(new Date(), 0),
                key: "selection",
            },
        ]);
    }

    const filterData = () => {
        const metroData = metro.map(item => item.value)
        const status = statusFilter.map(item => item.value)
        const stateData = selectState.map(item => item.value)
        const company = companyData ? companyData?.filter((item, i) => item.label !== "Not Selected").map(item => item.id) : []
        const isNotSelected = companyData.some((item, i) => item.label === "Not Selected")
        const daysData = days?.map((e) => e?.id)
        const timeData = time?.map((e) => e?.id)

        return {
            metro: metroData,
            state: stateData,
            status: status,
            isBackgroundCheck: isBackgroundCheck,
            shifFromDate: fromDate,
            shifToDate: toDate,
            isShiftCount: isShiftStatus,
            scoreFrom: fromScoreRange,
            scoreTo: toScoreRange,
            orderCountFrom: fromShiftRange,
            orderCountTo: toShiftRange,
            firstOrderDriver: isFirstDriver,
            onBoardFrom: onboardingFrom,
            onBoardTo: onboardingTo,
            InterestedFrom: intrestDriversFromDate,
            InterestedTo: intrestDriversToDate,
            hidecolumn: hideColumn,
            showcolumn: columndata,
            company: company,
            isNotSelected: isNotSelected,
            availableDays: daysData,
            availableTime: timeData,
            timeAmount: timeAmount?.id,
            isCoaching: isCoaching,
            isbtReviewUnverified: isBtReview,
            expirationFromDate: expiredFromDate,
            expirationToDate: expiredToDate,
            isExpired: isExpired
        }
    }

    const handlesavedFilter = async () => {
        if (title) {
            setOpenModal(false)
            const filterdata = filterData()
            const savedNewData = {
                screenname: "driver",
                filtername: title.trim(),
                filterdata: filterdata
            }

            await savedFilter(savedNewData)
            setTitle("")
            if (!savedData.error) {
                sendSuccessMessage("Saved Successfully");
            }
        } else {
            setIsTitle(true)
        }
    }

    const saveFilter = () => {
        const isDateValue = fromDate || toDate || intrestDriversFromDate || intrestDriversToDate || onboardingFrom || onboardingTo || expiredFromDate || expiredToDate
        const isCheck = isBackgroundCheck || isFirstDriver || isShiftStatus || isCoaching || isBtReview || isExpired
        const isRangevalue = fromShiftRange || toShiftRange || fromScoreRange || toScoreRange
        const isDropdownValue = metro.length && metro || statusFilter.length && statusFilter || companyData.length && companyData ||
            days.length && days || time.length && time || selectState

        if (isDateValue || isCheck || isRangevalue || isDropdownValue?.length || hideColumn?.length || timeAmount?.value) {
            setOpenModal(true)
        } else {
            sendErrorMessage("Please Select Filter First")
        }
    }

    const updateFilter = async () => {

        setOpenModal(false)
        const filterdata = filterData()
        const savedNewData = {
            filtername: saveData.value,
            filterdata: filterdata
        }
        const update = {
            id: getID,
            body: savedNewData
        }
        await updateSavedFilter(update)
        if (!updateData.error) {
            sendSuccessMessage("Update Successfully");
        }
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        let add,
            show = columndata,
            hide = hideColumn

        if (source.droppableId === "showColumn") {
            add = show[source.index];
            show.splice(source.index, 1)
        } else {
            add = hide[source.index];
            hide.splice(source.index, 1)
        }

        if (destination.droppableId === "showColumn") {
            show.splice(destination.index, 0, add)
        } else {
            hide.splice(destination.index, 0, add)
        }
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={openDrawer}
                transitionDuration={{ enter: 1000, exit: 1000 }}
            >
                <Box className='main_contain' role="presentation" >
                    <div className="drawer_header">
                        <div className="d-flex">
                            <span className='mr-3' style={{ lineHeight: "2rem", fontSize: "2rem", color: "#4195f5" }}><BsFillFilterCircleFill /></span>
                            <Typography style={{ fontSize: '24px', fontWeight: '600' }}>
                                {'Advanced Search'}
                            </Typography>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                            <div>
                                <ReactSelect
                                    options={getsavedData?.map((item) => {
                                        return {
                                            value: item?.filtername,
                                            label: item?.filtername,
                                        };
                                    })}
                                    isClearable={true}
                                    onChange={(item) => {
                                        onSaveDataChange(item)
                                    }}
                                    placeholder="Saved Filter"
                                    value={saveData}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    styles={styles()}
                                />
                            </div>
                            <div style={{ cursor: 'pointer', color: "#adbfc7" }} onClick={() => handleCloseDrawer()}>
                                <CloseIcon fontSize='medium' />
                            </div >
                        </div>
                    </div>
                    {isFetching ? <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                        <CircularProgress className="loading-progress" />
                    </div> : (<>
                        <div style={{ margin: "100px 30px" }}>
                            <MuiAccordion className="mb-4" >
                                <MuiAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="panel1a-header"
                                >
                                    <Typography>Metro/Hub </Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <div>
                                        <div className='mb-3'>
                                            <label style={{ fontWeight: "normal" }} className="m-0 mb-1">
                                                <MdPlace /> Metro &nbsp;
                                            </label>
                                            <MultiSelect
                                                placeholder="Select Metro"
                                                value={metro}
                                                itemData={metros?.map((item, i) => {
                                                    return {
                                                        value: item?.name ? item?.name : "-",
                                                        label: `${item?.name ? item?.name : ""}`,
                                                    };
                                                })}
                                                styles={filterReactSelectStyle("100%")}
                                                onChange={(value) => {
                                                    setMetro(value)
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label style={{ fontWeight: "normal" }} className="m-0 mb-1" >
                                                State
                                            </label>
                                            <MultiSelect
                                                value={selectState}
                                                placeholder="Select State"
                                                itemData={states?.map((item, i) => {
                                                    return {
                                                        value: item?.state ? item?.state : "-",
                                                        label: `${item?.title ? item?.title : ""}`,
                                                    };
                                                })}
                                                styles={filterReactSelectStyle("100%")}
                                                onChange={(value) => {
                                                    setSelectState(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </MuiAccordionDetails>
                            </MuiAccordion>
                            <MuiAccordion className="mb-4">
                                <MuiAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Driver</Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <div className="accordionDetails" style={{ gap: "20px" }} >
                                        <div style={{ width: "48%" }}>
                                            <label className='m-0 mb-1' id="bg_checker" style={{ fontWeight: "normal" }}> Onboarded Range </label>
                                            <DatePicker
                                                handleCustomRange={onBoardingRange}
                                                fromDate={onboardingFrom}
                                                toDate={onboardingTo}
                                                showDateRangePicker={showOnboardPicker}
                                                onChange={(item) => {
                                                    setOnboardingState([item.selection]);
                                                }}
                                                ranges={onboardingstate}
                                                cancelDatePicker={() => {
                                                    setShowOnboardPicker(false);
                                                }}
                                                handleTimeRangeSelection={handleOnboardedTimeRange}
                                                clearTimeRange={clearOnboardingTimeRange}
                                                sx={{ position: 'relative' }}
                                                placeholder="Select Onboarded Range"
                                            />
                                        </div>
                                        <div className='toggle_box' style={{ marginTop: "25px", color: "#838383", width: "48%" }}>
                                            <label className='m-0' id="bg_checker" style={{ fontWeight: "normal", fontSize: "0.9rem" }}> Background Checker </label>
                                            <FormControlLabel
                                                control={<Android12Switch checked={isBackgroundCheck} onChange={(e) => setIsBackgroundCheck(e.target.checked)} />}
                                                style={{ marginTop: "8px" }}
                                                id="bg_checker"
                                            />
                                        </div>
                                    </div>
                                    <div className="accordionDetails mt-3" style={{ gap: "20px", flexWrap: "nowrap" }}>
                                        <div className="w-50">
                                            <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                                                Status
                                            </label>
                                            <MultiSelect
                                                placeholder="Select Status"
                                                itemData={driverReportStatus?.map((item, i) => {
                                                    return {
                                                        value: item?.value ? item?.value : "-",
                                                        label: `${item?.title ? item?.title : ""}`,
                                                    };
                                                })}
                                                value={statusFilter}
                                                styles={filterReactSelectStyle("100%")}
                                                onChange={(value) => {
                                                    setStatusFilter(value);
                                                }}
                                            />
                                        </div>
                                        <div className="w-50">
                                            <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                                                Days
                                            </label>
                                            <MultiSelect
                                                placeholder="Select Days"
                                                value={days}
                                                itemData={weekdays?.map((item, i) => {
                                                    return {
                                                        value: item,
                                                        label: item,
                                                        id: i
                                                    };
                                                })}
                                                styles={filterReactSelectStyle("100%")}
                                                width="100%"
                                                onChange={(value) => {
                                                    setDays(value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="accordionDetails mt-3" style={{ gap: "20px", flexWrap: "nowrap" }}>
                                        <div className="w-50">
                                            <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                                                Time
                                            </label>
                                            <MultiSelect
                                                placeholder="Select Time"
                                                value={time}
                                                itemData={scheduleTime?.map((item, i) => {
                                                    return {
                                                        value: item,
                                                        label: item,
                                                        id: i
                                                    };
                                                })}
                                                onChange={(value) => {
                                                    setTime(value)
                                                }}
                                                styles={filterReactSelectStyle("100%")}
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-50">
                                            <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                                                Time Amount
                                            </label>

                                            <ReactSelect
                                                options={sched_init.map(function (item) {
                                                    return {
                                                        value: `${item?.title ? item?.title : ""}`,
                                                        label: `${item?.title ? item?.title : ""}`,
                                                        id: item.id
                                                    }
                                                })}
                                                isClearable={true}
                                                onChange={(item) => {
                                                    setTimeAmount(item)
                                                }}
                                                value={timeAmount}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                }}
                                                styles={styles("100%")}
                                                closeMenuOnSelect={true}
                                                placeholder={`Select Time Amount`}
                                            />
                                        </div>
                                    </div>
                                    <div className="accordionDetails mt-3">
                                        <div style={{ width: "48%" }}>
                                            <label className='m-0 mb-1' id="bg_checker" style={{ fontWeight: "normal" }}> Driver License/Insurance Expiry Range </label>
                                            <DatePicker
                                                handleCustomRange={expiryRange}
                                                fromDate={expiredFromDate}
                                                toDate={expiredToDate}
                                                showDateRangePicker={showExpired}
                                                onChange={(item) => {
                                                    setExpiredState([item.selection]);
                                                }}
                                                ranges={expiredState}
                                                cancelDatePicker={() => {
                                                    setShowExpired(false);
                                                }}
                                                handleTimeRangeSelection={handleExpiryTimeRange}
                                                clearTimeRange={clearExpiryDriverTimeRange}
                                                sx={{ position: 'relative', backgroundColor: isExpired && "#fafafa" }}
                                                placeholder="Select Expiry Range"
                                            />
                                        </div>
                                        <div className=' toggle_box' style={{ width: "48%", marginTop: "25px", }} >
                                            <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> Coaching </label>
                                            <FormControlLabel
                                                control={<Android12Switch checked={isCoaching} onChange={(e) => {
                                                    setIsCoaching(e.target.checked);
                                                }} />}
                                                style={{ marginTop: "8px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="accordionDetails mt-4" >
                                        <div className=' toggle_box' style={{ width: "48%", }}  >
                                            <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> Driver License/Insurance Expired? </label>
                                            <FormControlLabel
                                                control={<Android12Switch checked={isExpired} onChange={(e) => {
                                                    setIsExperied(e.target.checked);
                                                    setExpiredFromDate("")
                                                    setExpiredToDate("")
                                                }} />}
                                                style={{ marginTop: "8px" }}
                                            />
                                        </div>
                                        <div className=' toggle_box' style={{ width: "48%", }} >
                                            <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> BT Review </label>
                                            <FormControlLabel
                                                control={<Android12Switch checked={isBtReview} onChange={(e) => {
                                                    setIsBtReview(e.target.checked);
                                                }} />}
                                                style={{ marginTop: "8px" }}
                                            />
                                        </div>
                                    </div>



                                </MuiAccordionDetails>
                            </MuiAccordion>
                            <MuiAccordion className="mb-4">
                                <MuiAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel3a-header"
                                >
                                    <Typography>Shift </Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <div>
                                        <div className="accordionDetails">
                                            <div style={{ width: "48%" }}>
                                                <label className='m-0 mb-1' id="bg_checker" style={{ fontWeight: "normal" }}> Shift Date Range </label>
                                                <DatePicker
                                                    handleCustomRange={handleCustomRange}
                                                    fromDate={fromDate}
                                                    toDate={toDate}
                                                    showDateRangePicker={showDateRangePicker}
                                                    onChange={(item) => {
                                                        setState([item.selection]);
                                                    }}
                                                    ranges={state}
                                                    cancelDatePicker={() => {
                                                        setShowDateRangePicker(false);
                                                    }}
                                                    handleTimeRangeSelection={handleTimeRangeSelection}
                                                    clearTimeRange={clearTimeRange}
                                                    sx={{ position: 'relative' }}
                                                    placeholder="Select Shift Date Range"
                                                />
                                            </div>
                                            <div className='toggle_box' style={{ height: "48px", width: "48%", marginTop: "22px" }}>
                                                <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> Shift Count </label>
                                                <FormControlLabel
                                                    control={<Android12Switch checked={isShiftStatus} onChange={(e) => setIsShiftStatus(e.target.checked)} />}
                                                    style={{ marginTop: "8px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className='accordionDetails mt-4'>
                                            <div style={{ width: "48%" }}>
                                                <label className='m-0 mb-1' id="bg_checker" style={{ fontWeight: "normal" }}> Interested Route Range </label>
                                                <DatePicker
                                                    handleCustomRange={intrestDriverRange}
                                                    fromDate={intrestDriversFromDate}
                                                    toDate={intrestDriversToDate}
                                                    showDateRangePicker={showIntrestDrivers}
                                                    onChange={(item) => {
                                                        setIntrestDriverState([item.selection]);
                                                    }}
                                                    ranges={intrestDriverState}
                                                    cancelDatePicker={() => {
                                                        setShowIntrestDrivers(false);
                                                    }}
                                                    handleTimeRangeSelection={handleIntrestDriverTimeRange}
                                                    clearTimeRange={clearintrestDriverTimeRange}
                                                    sx={{ position: 'relative' }}
                                                    placeholder="Select Interested Route Range"
                                                />
                                            </div>
                                        </div>

                                        <div className="accordionDetails">
                                            <div className="mt-3 mr-3">
                                                <label style={{ fontWeight: "normal" }} className="m-0 mb-1 " >
                                                    Shifts Range :
                                                </label>
                                                <div className=" ranges " style={{ alignItems: "center" }}>
                                                    <input className={`form__input`} type="text"
                                                        onChange={(e) => {
                                                            const re = /^-?\d*\.{0,1}\d+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                setFromShiftRange(e.target.value)
                                                            }
                                                        }}
                                                        value={fromShiftRange}
                                                    />
                                                    <Label
                                                        style={{ padding: "0px 6px" }}
                                                        component="span"
                                                        title="To"
                                                    />
                                                    <input className={`form__input`} type="text"
                                                        onChange={(e) => {
                                                            const re = /^-?\d*\.{0,1}\d+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                setToShiftRange(e.target.value)
                                                            }
                                                        }}
                                                        value={toShiftRange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 mr-3">
                                                <label style={{ fontWeight: "normal" }} className="m-0 mb-1 ">
                                                    Score Range :
                                                </label>
                                                <div className=" ranges " style={{ alignItems: "center" }}>
                                                    <input
                                                        className={`form__input`}
                                                        type="number"
                                                        onChange={(e) => setFromScoreRange(e.target.value)}
                                                        value={fromScoreRange}
                                                    />
                                                    <Label
                                                        style={{ padding: "0px 6px" }}
                                                        component="span"
                                                        title="To"
                                                    />
                                                    <input
                                                        className={`form__input`}
                                                        type="number"
                                                        onChange={(e) => setToScoreRange(e.target.value)}
                                                        value={toScoreRange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MuiAccordionDetails>
                            </MuiAccordion>
                            <MuiAccordion className="mb-4">
                                <MuiAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel4a-header"
                                >
                                    <Typography>New drivers</Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <div className=' toggle_box' >
                                        <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> First Drivers </label>
                                        <FormControlLabel
                                            control={<Android12Switch checked={isFirstDriver} onChange={(e) => {
                                                setIsFirstDriver(e.target.checked);
                                                setIsShiftStatus(e.target.checked);
                                            }} />}
                                            style={{ marginTop: "8px" }}
                                        />
                                    </div>
                                </MuiAccordionDetails>
                            </MuiAccordion>
                            <MuiAccordion className="mb-4">
                                <MuiAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel4a-header"
                                >
                                    <Typography>Company</Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <div className='mb-3'>
                                        <label style={{ fontWeight: "normal" }} className="m-0 mb-1">
                                            Company
                                        </label>
                                        <MultiSelect
                                            placeholder="Select Company"
                                            value={companyData}
                                            itemData={
                                                getCompany
                                            }
                                            styles={filterReactSelectStyle("100%")}
                                            onChange={(value) => {
                                                setCompanyData(value)
                                            }}
                                        />
                                    </div>
                                </MuiAccordionDetails>
                            </MuiAccordion>
                            <MuiAccordion className="mb-4">
                                <MuiAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel4a-header"
                                >
                                    <Typography>Column Settings</Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <div className="column_Container" >
                                            <div className='showColumn' >
                                                <h4>Show Column</h4>
                                                <Droppable droppableId={"showColumn"}>
                                                    {(provided, snapshot) => (
                                                        <div sx={{ textAlign: "center", height: "500px", }}
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            isDraggingOver={snapshot.isDraggingOver}
                                                        >
                                                            <div style={{ height: "450px", padding: "5px 30px", overflow: "auto" }}>
                                                                {columndata.map((item, index) => (
                                                                    <Draggable draggableId={`${item}`} key={item} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <Paper
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                                isDragging={snapshot.isDragging}
                                                                                sx={{ margin: "10px 0", bgcolor: "lightgray", cursor: "pointer" }}
                                                                            >
                                                                                <ListItem disablePadding sx={{ padding: "5px 10px" }}>
                                                                                    {item}
                                                                                </ListItem>
                                                                            </Paper>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                            <div className='hideColumn' >
                                                <h4>Hide Column</h4>
                                                <Droppable droppableId="hideColumn">
                                                    {(provided, snapshot) => (
                                                        <div sx={{ textAlign: "center", height: "500px", width: "300px" }}
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            isDraggingOver={snapshot.isDraggingOver}
                                                        >
                                                            <div style={{ height: "450px", padding: "0px 30px", overflow: "auto" }}>
                                                                {hideColumn.map((item, index) => (
                                                                    <Draggable draggableId={`${item}`} key={item} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <Paper
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                                isDragging={snapshot.isDragging}
                                                                                sx={{ margin: "10px 0", bgcolor: "lightgray", cursor: "pointer" }}
                                                                            >
                                                                                <ListItem disablePadding sx={{ padding: "5px 10px" }}>
                                                                                    {item}
                                                                                </ListItem>
                                                                            </Paper>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                            </div>
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                        </div>
                                    </DragDropContext>
                                </MuiAccordionDetails>
                            </MuiAccordion>
                        </div>
                    </>)}
                    <div className="drawer_footer">
                        {!saveData ? <button className="btn mr-4 pl-4 pr-4" onClick={saveFilter}> Save Filter </button>
                            : <button className="btn mr-4 pl-4 pr-4" onClick={updateFilter}> Update Filter </button>}
                        <button className="btn mr-4 pl-4 pr-4" onClick={() => handleReset()}> Reset </button>
                        <button className="btn btn_accent mr-3 pl-4 pr-4" onClick={() => handleApplyFilter()}> Apply </button>
                    </div>
                </Box>
                <Modal
                    open={openModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal filter_modal">
                        <div className="modal__header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h2 className="modal__title">Saved Filter</h2>
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                setOpenModal(false)
                                setIsTitle(false)
                                setTitle("")
                            }}>
                                <CloseIcon fontSize='medium' />
                            </div>
                        </div>
                        <div style={{ fontWeight: '600', padding: "30px" }}>
                            <InputLabel htmlFor="file">Filter Name : &nbsp;</InputLabel>
                            <input
                                className={`form__input`}
                                type="text"
                                id="file"
                                style={{ border: "1px solid #ccd7dd", borderRadius: "2px" }}
                                placeholder="Enter Filter Name"
                                onChange={(e) => {
                                    let data = e.target.value.trimStart()
                                    setTitle(data)
                                }}
                                value={title}
                            />
                            {isTitle && !title && <p className="mb-0 mt-1" style={{ color: "red", fontWeight: "normal" }}>*Please Enter Filter Name</p>}
                        </div>
                        <div className="modal__footer">
                            <button className="btn" onClick={handlesavedFilter}>
                                Save
                            </button>
                        </div>
                    </div>
                </Modal>
            </Drawer>
        </>
    )
}

export default CustomDrawer

export const DatePicker = (props) => {
    const { handleCustomRange, fromDate, toDate, showDateRangePicker, onChange, ranges, handleTimeRangeSelection, cancelDatePicker, clearTimeRange, sx, placeholder, className } = props

    const styles = {
        position: 'absolute',
        top: 37,
        right: 0,
        left: 0,
        zIndex: 1,
        p: 1,
        width: "103%"
    };
    return (
        <>
            <Box sx={sx} style={{ width: "100%" }}>
                <div onClick={handleCustomRange} className={`form__input ${className && className} d-flex justify-content-between`}
                    style={{ borderRadius: "2px", border: "1px solid #e3eaf3", color: "#838383", fontSize: "0.9rem" }}>
                    <span>
                        {`${fromDate?.length
                            ? `${moment(fromDate).format(
                                "MM-DD-yyyy"
                            )} TO ${moment(toDate).format("MM-DD-yyyy")}`
                            : placeholder
                            }`}
                    </span>
                    <span className="pl-2">
                        {showDateRangePicker ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </span>
                </div>
                {showDateRangePicker && (
                    <Box sx={styles} className="dt__rng__pickr__cont">
                        <div className="dateRangePicker" style={{ right: "!important" }}>
                            <DateRangePicker
                                onChange={onChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={ranges}
                                direction="horizontal"
                            />

                            <div className="button-container">
                                <button
                                    onClick={clearTimeRange}
                                    className="okButton clearButton"
                                >
                                    <span>
                                        <BackspaceIcon /> &nbsp;
                                        <strong>CLEAR</strong>
                                    </span>
                                </button>
                                <button
                                    onClick={cancelDatePicker}
                                    className="cancelButton"
                                >
                                    <span>
                                        <CancelIcon sx={{ width: "1.5rem", height: "1.5rem" }} />{" "}
                                        <strong>cancel</strong>
                                    </span>
                                </button>
                                <button
                                    onClick={handleTimeRangeSelection}
                                    className="okButton"
                                >
                                    <span>
                                        <ThumbUpAltIcon /> &nbsp;
                                        <strong>Done</strong>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Box>
                )}
            </Box>
        </>
    )
}


