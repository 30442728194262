import { drivers } from "./variables";
const { DELIVERY_STARTED } = drivers;

export const orderStatuses = [
  { title: "All", value: "all" },
  { title: "Created", value: "created" },
  { title: "Assigned", value: "assigned" },
  { title: "Rejected", value: "rejected" },
  { title: DELIVERY_STARTED, value: "delivery_started" },
  { title: "Complete", value: "complete" },
  { title: "Paid", value: "paid" },
  { title: "Waiting For Approval", value: "approved" },
  { title: "Cancelled", value: "cancelled" },
];

export const changeRoleDatas = [
  { title: "Root", value: "root" },
  { title: "Admin", value: "admin" },
  { title: "BookKeeper", value: "bookkeeper" },
  { title: "Metro Manager", value: "metro_manager" },
];

export const driversStatus = [
  { title: "All", value: "all" },
  { title: "Unverified", value: "unverified" },
  { title: "Not Approved", value: "not_approved" },
  { title: "Approved", value: "approved" },
  { title: "Inactive", value: "inactive" },
  { title: "Rejected", value: "rejected" },
  // { title: "locked", value: "blocked" },
  { title: "Offboarded", value: "offboarded" },
];

export const driverReportStatus = [
  { title: "Unverified", value: "unverified" },
  { title: "Not Approved", value: "not_approved" },
  { title: "Approved", value: "approved" },
  { title: "Inactive", value: "inactive" },
  { title: "Rejected", value: "rejected" },
  { title: "Offboarded", value: "offboarded" },
]

export const shiftReportStatus = [
  { title: "Created", value: "created" },
  { title: "Assigned", value: "assigned" },
  { title: "Rejected", value: "rejected" },
  { title: "Delivery started", value: "delivery_started" },
  { title: "Complete", value: "complete" },
  { title: "Paid", value: "paid" },
  { title: "Waiting For Approval", value: "approved" },
  { title: "Cancelled", value: "cancelled" },
]

export const driverArriva = [
  { title: "Arrival on time", value: "1" },
  { title: "Arrival late", value: "2" },
]


export const payment = [
  { title: "Weekly Payment", value: "Weekly Payment" },
  { title: "Instant Payment", value: "Instant Payment" },
  { title: "Adhoc Payments", value: "AdhocPayments" },
  { title: "Shift Payment", value: "Shift Payment" },
  { title: "Payed with exceptions", value: "Exceptions Payment" },
  { title: "All Payments", value: "All Payment" },
]

export const rejectShiftScoreReason = [
  { title: "By Admin", value: "by_admin" },
  { title: "By Call", value: "by_call" },
  { title: "Not Shown", value: "not_shown" },
];

export const cancleShiftScoreReason = [
  { title: "Excess drivers", value: "excess_drivers" },
  { title: "Driver canceled", value: "driver_canceled" },
  { title: "No call, no show", value: "no_call" },
  { title: "Arrived late", value: "arrived_late" },
  { title: "Other", value: "other" },
];

export const scheduleDetails = [
  { title: "Actual", value: "actualShifts", custom_class: "bg__actual" },
  { title: "Created", value: "blocksCreated", custom_class: "bg__blocks" },
  { title: "Assigned", value: "assigned", custom_class: "bg__assigned" },
  { title: "Deficit", value: "defitieted", custom_class: "bg__defitieted" },
  {
    title: "Interested D.",
    value: "interestedDefitieted",
    custom_class: "bg__interested",
  },
];

export const arrOfTitle = [
  { label: "New", name: "new", width: "24%" },
  { label: "Shifts", name: "ws", width: "24%" },
];

export const arrOfStatusesCount = [
  { created: 0 },
  { assigned: 0 },
  { rejected: 0 },
  { delivery_started: 0 },
  { complete: 0 },
  { remain: 0 },
];

export const layoutOptionForPipelineScreen = [
  { title: "Pipeline Drivers", value: "pipeline_drivers" },
  { title: "Active Drivers", value: "active_drivers" },
  { title: "Actual Shifts", value: "actual_shifts" },
];

export const hideDriverColumn = ["Name", "Username", "Company", "Phone", "Metro", "Shifts", "Shift Last Date", "Score", "Status", "Created", "Date Onboard Complete", "Pipeline Stage", "Notes"]

export const hideShiftColumn = ["Metro", "Route Code", "Route Date", "Start Time", "Payout", "Driver Name", "Driver Phone Number", "Notes", "Internal notes", "Status", "Cancel Status"]