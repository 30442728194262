import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IoChevronForward, IoSearchSharp } from "react-icons/io5";
import Table from "../shared/Table";
import { useGetStoresQuery } from "../../api/stores";
import { actions } from "../../redux/store/store";
import { MdPlace } from "react-icons/md";
import MetroSelect from "../shared/metroSelect";

import "../../styles/_data.scss";
import "./style.scss";
import "../../index.css";
import { CircularProgress } from "@mui/material";
import { IoIosAddCircle } from "react-icons/io";
import HeaderTextLayout from "../shared/HeaderTextLayout";
import { Refresh, Clear } from "@mui/icons-material";

export default function Stores() {
  const loc = useLocation();
  const { data: stores, isFetching, refetch } = useGetStoresQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const metro = useSelector((state) => state.metro.value);

  const [filteredStores, setFilteredStores] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [currentURL, setCurrentURL] = useState(loc.pathname.substring(1));
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentURL(loc.pathname.substring(1));
  }, [loc]);

  useEffect(() => {
    setLoading(isFetching);
    if (!stores) return;
    
    handleSearchingHubs()
  }, [stores, metro, isFetching]);

  const [activeItem, setActiveItem] = useState();
  const modalOrderOpen = useSelector((state) => state.modal.store.open);

  const handleSearchingHubs =  (value) => {

    setFilteredStores(
      metro ? stores.filter((order) => order.metro === metro) : stores
    );

    setFilteredStores((filteredStores) =>
        filteredStores.filter((store) => {
          return (
            (store.name &&
              store.name.toLowerCase().trim().includes(value === 'CLEAR' ? '' : searchQuery.toLowerCase().trim())) ||
            (store.address &&
              store.address.toLowerCase().trim().includes(value === 'CLEAR' ? '' : searchQuery.toLowerCase().trim()))
          );
        })
      );

  }
  

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingHubs();
    }
  };

  useEffect(() => {
    let timer = false;
    if (!modalOrderOpen) {
      timer = setTimeout(() => {
        setActiveItem(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [modalOrderOpen]);

  const getActiveItem = (id) => {
    setActiveItem(id);
  };

  const columns = [
    { name: "metro", label: "Metro Code", sortable: true },
    { name: "name", label: "Name", sortable: true },
    { name: "phone", label: "Phone", sortable: true },
    { name: "address", label: "Address", sortable: true },
    {
      name: "details",
      className: "table__td_have-btn",
      renderer: (item) => (
        <button
          className="btn btn_accent customized-details-btn"
          type="button"
          onClick={() => {
            actions.modal.openStore(item._id);
            getActiveItem(item._id);
          }}
        >
          Details <IoChevronForward className="btn__icon btn__icon_right" />
        </button>
      ),
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Hubs"
              className="col-md-2 main__driver"
              data={
                filteredStores && filteredStores.length > 0
                  ? `${filteredStores.length} Stores Found`
                  : ""
              }
              dataLength={
                Array.isArray(filteredStores) ? filteredStores.length : "0"
              }
            >
              <div className="ml-auto col-md-10 pull-right d-flex justify-content-end align-items-center">
                <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end pr-2">
                  <div className="searchbar__container pr-2">

                  <input
                    className="pr-2 form__input customized__input"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      if (e.target.value === "")
                      handleSearchingHubs("CLEAR");
                    }}
                    placeholder="Quick Search"
                    onKeyDown={handleKeyDown}
                  />
                  {searchQuery?.length > 0 && (
                    <Clear
                      className="clear__btn filter__search-icon"
                      onClick={() => {
                        setSearchQuery("");
                        handleSearchingHubs("CLEAR");
                      }}
                      style={{
                        right: "45px",
                        top: "12px",
                      }}
                    />
                  )}
                  <button
                    disabled={searchQuery?.length <= 0}
                    className="search__button"
                    onClick={() => handleSearchingHubs()}
                  >
                    <IoSearchSharp className="filter__search-icon" />
                  </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {/* <MdPlace className="mr-1" />
                  <p style={{ margin: "auto 3% auto 0", fontWeight: "normal" }}>
                    Metro:
                  </p> */}
                  <MetroSelect
                    value={metro || ""}
                    onChange={(event, newValue) =>
                      actions.metro.selectMetro(newValue)
                    }
                    className="main_metrodropdown"
                  />
                </div>

                {currentURL === "hubs" && (
                  <div>
                    <button
                      style={{
                        background: "white",
                        borderColor: "#e6e6e6",
                        padding: "0 20px"
                      }}
                      className="btn btn__accent mr-0 ml-3"
                      onClick={() => actions.modal.openStore()}
                    >
                      <IoIosAddCircle style={{ fontSize: "22px" }} />
                      New Hub
                    </button>
                  </div>
                )}
                 <button className="refresh_btn ml-3" onClick={() => refetch()}>
                  <Refresh className="refresh_icon" />
                </button>
              </div>
            </HeaderTextLayout>
            {isLoading ? (
              <div className="loading-holder">
                <CircularProgress className="loading-progress" />
              </div>
            ) : (
              <React.Fragment>
                {filteredStores && filteredStores.length ? (
                  <div className="table-wrapper normal_table">
                    <Table
                      columns={columns}
                      items={filteredStores}
                      className="table_stores"
                      activeItem={activeItem}
                    />
                  </div>
                ) : (
                  <div className="no_results">No Results</div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
